<template>
	<Switch
		v-model="enabled"
		:class="enabled ? 'bg-cta' : 'bg-gray-200'"
		class="relative inline-flex h-6 w-11 items-center rounded-full"
	>
		<span
			:class="enabled ? 'translate-x-6' : 'translate-x-1'"
			class="inline-block h-4 w-4 transform rounded-full bg-white transition"
		/>
	</Switch>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { Switch } from '@headlessui/vue';

const props = defineProps<{ enabled: boolean }>();

const enabled = ref(props.enabled);

const emit = defineEmits(['toggle']);

watch(enabled, (newValue) => emit('toggle', newValue));
</script>
